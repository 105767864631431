/**
 * TODO: Rewrite to typescript once we are able to load TS files inside the `messenger` app.
 */
export const EventEmitter = {
  _events: {},
  publish(eventName, data) {
    if (!this._events[eventName]) return;
    this._events[eventName].forEach((callback) => callback(data));
  },
  subscribe(eventName, callback) {
    if (!this._events[eventName]) this._events[eventName] = [];
    this._events[eventName].push(callback);
  },
  unsubscribe(eventName, callback) {
    if (!this._events[eventName]) return;
    if (callback) {
      this._events[eventName] = this._events[eventName].filter(
        (storedCallback) => {
          if (callback === storedCallback) return false;
          return true;
        },
      );
    } else {
      delete this._events[eventName];
    }
  },
};
